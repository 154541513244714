import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { trigger } from "../modules/Events";

function LogIn() {
  const { id } = useParams();

  useEffect(() => {
    trigger("TitleChange", `Log In`);
  }, []);

  return <div>This is the Log-In Page. {id}</div>;
}

export default LogIn;
