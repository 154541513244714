import "../css/App.css";
import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import ListofLists from "../pieces/ListofLists";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { trigger } from "../modules/Events";

function ListManager({ todoLists }) {
  const navigate = useNavigate();

  useEffect(() => {
    trigger("TitleChange", `List Management`);
  }, []);

  return (
    <>
      <ListofLists todoLists={todoLists} allowDelete={true} />

      <div className="row">
        <button
          onClick={() => {
            navigate("/addlist");
          }}
        >
          Add List
        </button>
      </div>
    </>
  );
}

export default ListManager;
