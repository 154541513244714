import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { trigger } from "../modules/Events";

function About({ UserName }) {
  const navigate = useNavigate();

  useEffect(() => {
    trigger("TitleChange", `About Page`);
  }, []);

  return (
    <div>
      This is the about Page.
      <button
        onClick={() => {
          navigate("/");
        }}
      >
        Change to home
      </button>
      The active user is: {UserName}
    </div>
  );
}

export default About;
