import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function Todo({ todo, toggleTodo }) {
  function handleTodoClick() {
    toggleTodo(todo.id);
  }

  return (
    <>
      <FormControlLabel
        control={<Checkbox />}
        label={
          todo.category ? `${todo.name} (${todo.category})` : `${todo.name}`
        }
        onChange={handleTodoClick}
        checked={todo.complete}
      />
      {todo.details && (
        <label className="classes.helperText">{todo.details}</label>
      )}
    </>
  );
}
