import React from "react";
import { Link } from "react-router-dom";
import { deleteList } from "../modules/Firebase";

export default function ListSummary({ todoList, allowDelete }) {
  function handleRemoveList(id) {
    deleteList(id);
  }

  return (
    <div>
      <label>List Name: </label>
      <label>
        <Link to={`/list/${todoList.id}`}>{todoList.name}</Link>
      </label>
      <label>Type: </label>
      <label>{todoList.type}</label>
      <label>List Visibility </label>
      <label>{todoList.visibility}</label>
      <label>Categories: </label>
      <label>{todoList.categories?.join(", ")}</label>
      <div className="row">
        {allowDelete && (
          <button onClick={() => handleRemoveList(todoList.id)}>
            [Delete List]
          </button>
        )}
      </div>
    </div>
  );
}
