import "../css/App.css";
import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import TodoList from "../pieces/TodoList";
import { getListDetails, updateListDetails } from "../modules/Firebase";
import { on, trigger } from "../modules/Events";
import AddListItem from "../pieces/AddListItem";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

function List() {
  const { listId } = useParams();
  const [listDetails, setListDetails] = useState([]);
  const [completeChecked, setCompleteChecked] = useState(false);
  const [addItemChecked, setAddItemChecked] = useState(false);

  const handleCompleteChange = () => {
    setCompleteChecked((prev) => !prev);
  };

  const handleAddItemChange = () => {
    setAddItemChecked((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      const newDetails = await getListDetails(listId);
      setListDetails([newDetails]);
      on("ListDetailsUpdated", updateListOnChange);
      trigger("TitleChange", `${newDetails.name} - list`);
    };

    fetchData();
  }, [listId]);

  function updateListOnChange(changeEvent) {
    setListDetails([changeEvent.detail]);
  }

  function toggleTodo(id) {
    let newlistDetails = listDetails[0];
    const todo = newlistDetails.listItems.find((todo) => todo.id === id);

    todo.complete = !todo.complete;

    updateListDetails(newlistDetails);
  }

  function handleClearTodos() {
    const newlistDetails = listDetails[0];
    newlistDetails.listItems = listDetails[0].listItems.filter(
      (todo) => !todo.complete
    );

    updateListDetails(newlistDetails);
  }

  return (
    <>
      <FormControlLabel
        control={
          <Switch checked={addItemChecked} onChange={handleAddItemChange} />
        }
        label="Add Item(s)"
      />
      {addItemChecked && <AddListItem listDetails={listDetails[0]} />}

      <div>
        {listDetails[0]?.listItems?.filter((todo) => !todo.complete).length}{" "}
        left to do
      </div>
      {listDetails[0]?.type === "wish" && (
        <FormGroup>
          <TodoList
            todos={listDetails[0]?.listItems?.sort(function (a, b) {
              const nameA = a.name.toUpperCase(); // ignore upper and lowercase
              const nameB = b.name.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            })}
            toggleTodo={toggleTodo}
            displayFilter="none"
          />
        </FormGroup>
      )}

      {listDetails[0]?.type !== "wish" && (
        <>
          <FormGroup>
            <TodoList
              todos={listDetails[0]?.listItems?.sort(function (a, b) {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const catA = a.category?.toUpperCase();
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                const catB = b.category?.toUpperCase();
                if (catA < catB) {
                  return -1;
                }
                if (catA > catB) {
                  return 1;
                }

                if (catA === catB && nameA < nameB) {
                  return -1;
                }
                if (catA === catB && nameA > nameB) {
                  return 1;
                }

                // names must be equal
                return 0;
              })}
              toggleTodo={toggleTodo}
              displayFilter="showActive"
            />
          </FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={completeChecked}
                onChange={handleCompleteChange}
              />
            }
            label="Show Completed Items"
          />

          {completeChecked && (
            <Box>
              <Button variant="contained" onClick={handleClearTodos}>
                Clear Completed Items
              </Button>
              <FormGroup>
                <TodoList
                  todos={listDetails[0]?.listItems?.sort(function (a, b) {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })}
                  toggleTodo={toggleTodo}
                  displayFilter="showCompleted"
                />
              </FormGroup>
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default List;
