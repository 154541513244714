import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { addList } from "../modules/Firebase";
import { trigger } from "../modules/Events";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";

function AddList() {
  const navigate = useNavigate();
  const listNameRef = useRef();
  const [visibilityState, setVisibilityState] = useState("Private List");
  const [iconState, setIconState] = useState("LibraryAddCheckOutlinedIcon");
  const listCategoriesRef = useRef();
  const [radioType, setRadioType] = useState("simple");

  useEffect(() => {
    trigger("TitleChange", `List Management - Create List`);
  }, []);

  const DEFAULT_CATEGORIES = [
    "Uncategorized",
    "Produce",
    "Deli",
    "Dairy",
    "Meat & Seafood",
    "Dry Goods",
    "Freezer",
  ];

  function handleAddList(e) {
    const name = listNameRef.current.value;
    if (name === "") return; //must have a name to continue

    let categories = null;
    if (radioType === "categorized")
      categories = listCategoriesRef.current.value.split("\n");

    addList({
      name: name,
      type: radioType,
      categories: categories,
      visibility: visibilityState,
      icon: iconState,
    });

    navigate("/lists");
  }

  function onValueChange(e) {
    setRadioType(e.target.value);
  }

  const handleVisbilityChange = (event) => {
    setVisibilityState(event.target.value);
  };

  const handleIconChange = (event) => {
    setIconState(event.target.value);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <label>Add a New List</label>
        </div>
        <div className="row">
          <label>List Name:</label> <input ref={listNameRef} type="text" />
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="radio">
              <label>
                <input
                  name="listType"
                  type="radio"
                  value="simple"
                  checked={true}
                  onChange={onValueChange}
                  checked={radioType === "simple"}
                />
                Simple
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  name="listType"
                  type="radio"
                  value="categorized"
                  onChange={onValueChange}
                  checked={radioType === "categorized"}
                />
                Categorized
              </label>
              <TextField
                id="outlined-multiline-static"
                label="Categories"
                multiline
                rows={4}
                inputRef={listCategoriesRef}
                defaultValue={DEFAULT_CATEGORIES.join("\r\n")}
              />
            </div>
            <div className="radio">
              <label>
                <input
                  name="listType"
                  type="radio"
                  value="wish"
                  onChange={onValueChange}
                  checked={radioType === "wish"}
                />
                Wish List
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <InputLabel id="visibility-label">List Visibility</InputLabel>
          <Select
            sx={{ m: 1, minWidth: 300 }}
            labelId="visibility-label"
            onChange={handleVisbilityChange}
            value={visibilityState}
          >
            <MenuItem key="1" value="Private List">
              Private List
            </MenuItem>
            <MenuItem key="2" value="Group List">
              Group Shareable List
            </MenuItem>
            <MenuItem key="3" value="Wish List">
              Group Wish List
            </MenuItem>
            <MenuItem key="4" value="Public List">
              Public List
            </MenuItem>
          </Select>
        </div>
        <div className="row">
          <InputLabel id="icon-label">List Display Icon</InputLabel>
          <Select
            sx={{ m: 1, minWidth: 300 }}
            labelId="icon-label"
            onChange={handleIconChange}
            value={iconState}
          >
            <MenuItem key="1" value="LibraryAddCheckOutlinedIcon">
              <LibraryAddCheckOutlinedIcon />
            </MenuItem>
            <MenuItem key="2" value="CardGiftcardOutlinedIcon">
              <CardGiftcardOutlinedIcon />
            </MenuItem>
            <MenuItem key="3" value="LocalGroceryStoreOutlinedIcon">
              <LocalGroceryStoreOutlinedIcon />
            </MenuItem>
          </Select>
        </div>
        <div className="row">
          <Button variant="contained" onClick={handleAddList}>
            Add List
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              navigate("/lists");
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddList;
