import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { updateListDetails } from "../modules/Firebase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import { ContactPageSharp } from "@mui/icons-material";
const { v4: uuidv4 } = require("uuid");

export default function AddListItem({ listDetails }) {
  const [categoryState, setcategoryState] = useState("");
  const [taskNameState, setTaskNameState] = useState("");
  const [TaskDetailsState, setTaskDetailsState] = useState("");

  useEffect(() => {
    setTaskNameState("");
    setcategoryState("");
    setTaskDetailsState("");
  }, [listDetails]); //clear out the add item form when changing lists.

  if (!listDetails) return <label>List not found to add items to.</label>;

  function handleAddItem(e) {
    if (taskNameState === "") return;

    let newItem = { id: uuidv4(), name: taskNameState, complete: false };

    if (categoryState) newItem.category = categoryState;
    if (TaskDetailsState) newItem.details = TaskDetailsState;

    let newlistDetails = listDetails;
    newlistDetails.listItems = [...newlistDetails.listItems, newItem];

    updateListDetails(newlistDetails);

    setTaskNameState(""); //clear task name so another can be added
  }

  const handleCategoryChange = (event) => {
    setcategoryState(event.target.value);
  };

  const handleNameChange = (event) => {
    setTaskNameState(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setTaskDetailsState(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleAddItem(null);
    }
  };

  return (
    <Box
      component="main"
      sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}
      display="flex"
      alignItems="center"
    >
      <TextField
        id="todoName"
        label="Summary or Task Name"
        type="search"
        value={taskNameState}
        onChange={handleNameChange}
        onKeyDown={handleKeyDown}
        sx={{ m: 1, minWidth: 300 }}
      />

      {listDetails.categories.length > 0 && (
        <>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            sx={{ m: 1, minWidth: 300 }}
            labelId="category-label"
            onChange={handleCategoryChange}
            value={categoryState}
          >
            {listDetails.categories?.map((cat, i) => (
              <MenuItem key={i} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {listDetails.type === "wish" && (
        <TextField
          id="todoDescription"
          label="Description"
          type="search"
          value={TaskDetailsState}
          onChange={handleDescriptionChange}
          multiline
          rows={6}
          sx={{ m: 1, minWidth: 300 }}
        />
      )}
      <Button variant="contained" onClick={handleAddItem}>
        Add Item
      </Button>
    </Box>
  );
}
