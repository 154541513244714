import React from "react";
import Todo from "./Todo";

export default function TodoList({ todos, toggleTodo, displayFilter }) {
  if (todos) {
    var todoFilter = null;

    if (displayFilter === "showActive") {
      todoFilter = todos.filter((todo) => !todo.complete);
    } else if (displayFilter === "showCompleted") {
      todoFilter = todos.filter((todo) => todo.complete);
    } else {
      //none
      todoFilter = todos;
    }

    return todoFilter.map((todo) => {
      return <Todo key={todo.id} todo={todo} toggleTodo={toggleTodo} />;
    });
  } else {
    return <label>No List Items</label>;
  }
}
