import React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  currentFireBaseUser,
  signInWithGoogle,
  logOut,
} from "../modules/Firebase";

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { navlists, ...other } = props;

  const activeId = "1234596879";

  function userLogIn(e) {
    signInWithGoogle();
  }

  function userLogOut(e) {
    logOut();
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
        >
          Wish Attic
        </ListItem>
        {currentFireBaseUser() && (
          <>
            <Box key="My Lists" sx={{ bgcolor: "#101F33" }}>
              <ListItem sx={{ py: 2, px: 3 }}>
                <ListItemText sx={{ color: "#fff" }}>My Lists</ListItemText>
              </ListItem>
              {navlists
                ?.sort(function (a, b) {
                  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }

                  // names must be equal
                  return 0;
                })
                .map(({ id, name, icon, visibility }) => (
                  <ListItem
                    button
                    disablePadding
                    key={id}
                    component={Link}
                    to={`/list/${id}`}
                  >
                    <ListItemButton selected={activeId === id} sx={item}>
                      <ListItemIcon>
                        {icon === "LibraryAddCheckOutlinedIcon" && (
                          <LibraryAddCheckOutlinedIcon />
                        )}
                        {icon === "LocalGroceryStoreOutlinedIcon" && (
                          <LocalGroceryStoreOutlinedIcon />
                        )}
                        {icon === "CardGiftcardOutlinedIcon" && (
                          <CardGiftcardOutlinedIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText>{name}</ListItemText>
                      {visibility === "Public List" && (
                        <ListItemIcon>
                          <PublicOutlinedIcon />
                        </ListItemIcon>
                      )}
                      {visibility === "Group List" && (
                        <ListItemIcon>
                          <GroupsIcon />
                        </ListItemIcon>
                      )}
                      {visibility === "Wish List" && (
                        <ListItemIcon>
                          <GroupsIcon />
                        </ListItemIcon>
                      )}
                    </ListItemButton>
                  </ListItem>
                ))}

              <Divider sx={{ mt: 2 }} />
            </Box>
            <ListItem
              button
              sx={{ ...item, ...itemCategory }}
              component={Link}
              to="/lists"
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>List Management</ListItemText>
            </ListItem>
            <ListItem
              button
              sx={{ ...item, ...itemCategory }}
              component={Link}
              to="/addlist"
            >
              <ListItemIcon>
                <AddTaskOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Create List</ListItemText>
            </ListItem>
            <ListItem
              button
              sx={{ ...item, ...itemCategory }}
              onClick={userLogOut}
            >
              <ListItemIcon>
                <AddTaskOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Log Out</ListItemText>
            </ListItem>
          </>
        )}
        {!currentFireBaseUser() && (
          <ListItem
            button
            sx={{ ...item, ...itemCategory }}
            onClick={userLogIn}
          >
            <ListItemIcon>
              <AddTaskOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Log In</ListItemText>
          </ListItem>
        )}
        <ListItem
          button
          sx={{ ...item, ...itemCategory }}
          component={Link}
          to="/about"
        >
          <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <ListItemText>About Us</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
}
