import React from "react";
import ListSummary from "./ListSummary";

export default function ListofLists({ todoLists, allowDelete }) {
  if (!todoLists) return null;

  return todoLists?.map((list) => {
    return (
      <ListSummary key={list.id} todoList={list} allowDelete={allowDelete} />
    );
  });
}
