import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import AddList from "./pages/AddList";
import List from "./pages/List";
import ListManager from "./pages/ListManager";
import Header from "./pieces/Header";
import Navigator from "./pieces/Navigator";
import Footer from "./pieces/Footer";
import ErrorPage from "./pages/ErrorPage";
import LogIn from "./pages/LogIn";
import { PrivateRoute } from "./modules/PrivateRoute";
import { currentFireBaseUser, getLists, auth } from "./modules/Firebase";
import { on } from "./modules/Events";
import { CatchingPokemonSharp } from "@mui/icons-material";

const drawerWidth = 256;

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [todoLists, setTodoLists] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    auth.onAuthStateChanged(async (nextUser) => {
      if (auth.currentUser) {
        setIdToken(await auth.currentUser.getIdToken());
        setCurrentUser(auth.currentUser.displayName);
      } else {
        setCurrentUser(null);
        setIdToken(null);
      }
    });

    //setTodoLists(getLists());   //should no longer need because setting the user will trigger getting an updated list again.
    on("listOfListsUpdated", updateListOnChange);
    on("TitleChange", updateTitle);
  }, []); //empty array only changes the first time so will only run on initialization

  useEffect(() => {
    const fetchData = async () => {
      setTodoLists(await getLists());
    };

    fetchData();
  }, [currentUser]);

  function updateListOnChange(changeEvent) {
    setTodoLists(changeEvent.detail);
  }

  function updateTitle(changeEvent) {
    setPageTitle(changeEvent.detail);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", minHeight: "100vh" }}>
          <CssBaseline />
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            {isSmUp ? null : (
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                navlists={todoLists}
              />
            )}

            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              sx={{ display: { sm: "block", xs: "none" } }}
              navlists={todoLists}
            />
          </Box>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Header onDrawerToggle={handleDrawerToggle} pageTitle={pageTitle} />
            <Box
              component="main"
              sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/about"
                  element={<About UserName={currentUser} />}
                />
                <Route path="/login" element={<LogIn />} />

                <Route path="/lists" element={<PrivateRoute />}>
                  <Route
                    path="/lists"
                    element={<ListManager todoLists={todoLists} />}
                  />
                </Route>
                <Route path="/list" element={<PrivateRoute />}>
                  <Route path="/list" element={<List />} />
                </Route>
                <Route path="/list/:listId" element={<PrivateRoute />}>
                  <Route path="/list/:listId" element={<List />} />
                </Route>
                <Route path="/addlist" element={<PrivateRoute />}>
                  <Route path="/addlist" element={<AddList />} />
                </Route>

                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Box>
            <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
              <Footer />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;
