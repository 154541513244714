import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDoc,
  doc,
  collection,
  where,
  addDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { trigger } from "./Events";
const { v4: uuidv4 } = require("uuid");

const firebaseConfig = {
  apiKey: "AIzaSyDhA8RI4r8vKotqUf47D3eLwiZ45f1OpNw",
  authDomain: "wishattic-lists.firebaseapp.com",
  projectId: "wishattic-lists",
  storageBucket: "wishattic-lists.appspot.com",
  messagingSenderId: "462977635964",
  appId: "1:462977635964:web:4bde1a7a2f60348c0a9bf9",
  measurementId: "G-396642E3ZT",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    // const q = query(collection(db, "users"), where("uid", "==", user.uid));
    // const docs = await getDocs(q);
    // if (docs.docs.length === 0) {
    //   await addDoc(collection(db, "users"), {
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: "google",
    //     email: user.email,
    //   });
    //}
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

function currentFireBaseUser() {
  return auth.currentUser;
}

function currentFireBaseUserId() {
  if (auth.currentUser) {
    return auth.currentUser.uid;
  } else {
    return -1;
  }
}

function logOut() {
  signOut(auth);
}

const LOCAL_STORAGE_LISTS_COLLECTION = "todoApp.lists";
const LOCAL_STORAGE_DETAILS_COLLECTION = "todoApp.lists.details";
const LOCAL_STORAGE_LIST_DETAILS_KEY = ".todoApp.list.";

function arrayToObject(array) {
  if (!array) return {};

  return array.reduce((obj, item, currentIndex) => {
    if (item.id) {
      obj[item.id] = item;
    } else {
      obj[currentIndex] = item;
    }
    return obj;
  }, {});
}

function objectToArray(obj) {
  if (obj) {
    return Object.values(obj);
  } else {
    return [];
  }
}

async function getLists() {
  if (currentFireBaseUserId() == -1) return;

  const dbDoc = await getDoc(
    doc(db, LOCAL_STORAGE_LISTS_COLLECTION, currentFireBaseUserId())
  );

  if (dbDoc.data()) {
    let returnData = objectToArray(dbDoc.data());
    returnData.forEach((x, i) => (x.categories = objectToArray(x.categories)));

    return returnData;
  } else {
    await setDoc(
      doc(db, LOCAL_STORAGE_LISTS_COLLECTION, currentFireBaseUserId()),
      {}
    );

    return [];
  }
}

async function addList(list) {
  const listId = uuidv4();
  let todoLists = await getLists();

  const updatedLists = [
    ...todoLists,
    {
      id: listId,
      name: list.name,
      type: list.type,
      categories: list.categories,
      visibility: list.visibility,
      icon: list.icon,
    },
  ];

  updatedLists.forEach((x, i) => (x.categories = arrayToObject(x.categories)));

  await setDoc(
    doc(db, LOCAL_STORAGE_LISTS_COLLECTION, currentFireBaseUserId()),
    arrayToObject(updatedLists)
  );

  //create initial list
  await updateListDetails({
    id: listId,
    name: list.name,
    type: list.type,
    visibility: list.visibility,
    categories: list.categories,
    icon: list.icon,
    listItems: [],
  });

  updatedLists.forEach((x, i) => (x.categories = objectToArray(x.categories)));

  //notify listeners of new list of lists
  trigger("listOfListsUpdated", updatedLists);
}

async function deleteList(id) {
  const todoLists = await getLists();
  let newLists = todoLists.filter((list) => list.id !== id);

  await setDoc(
    doc(db, LOCAL_STORAGE_LISTS_COLLECTION, currentFireBaseUserId()),
    arrayToObject(newLists)
  );

  //notify listeners of new list of lists
  trigger("listOfListsUpdated", newLists);
}

async function getListDetails(id) {
  const dbDoc = await getDoc(
    doc(
      db,
      LOCAL_STORAGE_DETAILS_COLLECTION,
      `${currentFireBaseUserId()}${LOCAL_STORAGE_LIST_DETAILS_KEY}${id}`
    )
  );

  if (dbDoc) {
    let returnDetails = dbDoc.data();
    returnDetails.listItems = objectToArray(returnDetails.listItems);
    returnDetails.categories = objectToArray(returnDetails.categories);

    return returnDetails;
  } else {
    await setDoc(
      doc(
        db,
        LOCAL_STORAGE_DETAILS_COLLECTION,
        `${currentFireBaseUserId()}${LOCAL_STORAGE_LIST_DETAILS_KEY}${id}`
      ),
      {}
    );

    return [];
  }
}

async function updateListDetails(list) {
  let objList = Object.assign({}, list); //create a copy and not just a reference
  objList.listItems = arrayToObject(objList.listItems);
  objList.categories = arrayToObject(objList.categories);

  await setDoc(
    doc(
      db,
      LOCAL_STORAGE_DETAILS_COLLECTION,
      `${currentFireBaseUserId()}${LOCAL_STORAGE_LIST_DETAILS_KEY}${list.id}`
    ),
    objList
  );

  //notify listeners of new list of lists
  trigger("ListDetailsUpdated", list);
}

export {
  signInWithGoogle,
  currentFireBaseUser,
  logOut,
  getLists,
  addList,
  deleteList,
  getListDetails,
  updateListDetails,
};
